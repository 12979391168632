import React from "react";
import { Link } from "react-router-dom";
import {Icon} from "../../common/utils/Icon";
import { setShowCookiesCons } from "../../common/actions/cookies";
import { connect } from "react-redux";

function Footer({ navigations = [], setShowCookiesCons }) {
  function renderFooterLeft() {
    const nav = navigations.find((nav) => {
      return nav.position === "footer-left";
    });

    if (nav) {
      return (nav.children || []).map((child) => {
        return (
          <li key={child._id}>
            <Link to={(child.page && child.page.url) || child.url}>
              {child.label}
            </Link>
          </li>
        );
      });
    }
    return <></>;
  }

  function renderFooterRight() {
    const nav = navigations.find((nav) => {
      return nav.position === "footer-right";
    });

    if (nav) {
      return (nav.children || []).map((child) => {
        return (
          <div key={child._id}>
            <a
              href={child.url}
              target="_blank"
              rel="nofollow noopener"
              aria-label={`Weiter zu ${child.icon}`}
            >
              <i className="uk-icon-button footer-icon">
                {" "}
                <Icon icon={child.icon} />{" "}
              </i>
            </a>
          </div>
        );
      });
    }
    return <></>;
  }

  function renderFooterBottom() {
    const nav = navigations.find((nav) => {
      return nav.position === "footer-bottom";
    });

    if (nav) {
      return <>
        {
          (nav.children || []).map((child) => {
            return(
              <Link
                className="uk-navbar-item"
                key={child._id}
                to={(child.page && child.page.url) || child.url}
                style={{ color: "white" }}
              >
                {child.label}
              </Link>
            );
          })
        }
        <a
          className="uk-navbar-item"
          onClick={() => setShowCookiesCons(true, 2)}
          style={{ color: "white", cursor: "pointer" }}
        >
          Cookie-Einstellungen
        </a>
      </>


    }
    return <></>;
  }

  return (
    <footer>
      <section
        className="uk-section uk-section-secondary uk-section-small"
        style={{ backgroundColor: "#719613" }}
      >
        <div className="uk-container uk-container-expand">
          <p className="uk-h3 uk-margin-remove">
            Ein Unternehmen der Dorr Gruppe
          </p>
          <img
            className="uk-logo uk-margin lazyload"
            width={225}
            data-uk-img=""
            data-uk-svg=""
            data-src="/kutter-logo-inverse.svg"
            alt="Kutter Logo"
          />
          <div
            className="uk-flex uk-flex-middle uk-flex-between"
            data-uk-grid=""
          >
            <div>
              <ul className="uk-subnav">{renderFooterLeft()}</ul>
            </div>
            <div>
              <div className="uk-iconnav">{renderFooterRight()}</div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="uk-container uk-container-expand uk-light"
        style={{ backgroundColor: "#58770b" }}
      >
        <div className="uk-grid-margin uk-flex uk-flex-middle" data-uk-grid="">
          <div className="uk-width-expand@s uk-width-1-1 uk-visible@l">
            {/*© 2019 Kutter GmbH & Co. KG – Alle Rechte vorbehalten.*/}
          </div>
          <div
            className="uk-width-auto@s uk-width-1-1 uk-grid-small"
            data-uk-grid=""
          >
            {renderFooterBottom()}
          </div>
        </div>
      </div>
      <div className="uk-hidden@l" style={{ height: 63 }} />
    </footer>
  );
}

const mapDispatchToProps = {
  setShowCookiesCons,
};

export default connect(null, mapDispatchToProps)(Footer);
